import React from "react";
import PageTable from "./common/PageTable";
import PageHeader from "./common/PageHeader";
import Pagination from "./common/Pagination";
import { getPhones, getTotalPhonesCount } from "../services/phonesService";
import auth from "../services/authService";
import styled from "styled-components";
import { Grid, Paper, TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from "@mui/material";

const Container = styled.div`
  padding: 1rem;
`;
class Phones extends PageTable {
  state = {
    data: [],
    dataLength: 0,
    totalLength: 0,
    pageSize: 50,
    currentPage: 1,
    searchQuery: "",
    loading: true,
  };

  async componentDidMount() {
    const user = auth.getCurrentUser();
    if (user && (user.role === "support" || user.role === "admin")) {
      const { data } = await getPhones({
        page: this.state.currentPage,
        pagination: this.state.pageSize,
        phrase: this.state.searchQuery,
      });
      const { length } = await getTotalPhonesCount({
        phrase: this.state.searchQuery,
      });
      this.setState({
        data,
        dataLength: length,
        totalLength: length,
        loading: false,
      });
    }
  }

  /**
   * Called when Pagination or Search changes
   */
  handlePageChangeQuery = async (page, phrase) => {
    const user = auth.getCurrentUser();
    if (user && (user.role === "support" || user.role === "admin")) {
      this.setState({ loading: true });
      const { data } = await getPhones({
        page,
        pagination: this.state.pageSize,
        phrase,
      });
      const { length } = await getTotalPhonesCount({ phrase });
      this.setState({
        data,
        dataLength: length,
        currentPage: page,
        searchQuery: phrase,
        loading: false,
      });
    }
  };

  handleSearchChange = (query) => {
    this.setState({ searchQuery: query });
  };

  render() {
    
    const { data, dataLength, totalLength, pageSize, currentPage, searchQuery, loading } = this.state;
    const pageName = "Phone";
    const headers = ["Time", "Phone", "Product", "From SMS"];

    return (
      <Container>
        <PageHeader
          onSearch={this.handleSearchChange}
          onSearchSubmit={this.handlePageChangeQuery}
          pageName={pageName}
          sorted={data}
          count={dataLength}
          totalCount={totalLength}
          searchQuery={searchQuery}
          hasSearch={true}
          loading={loading}
        />

        {!loading && (
          <Grid container spacing={2}>
            {/* Table Section */}
            <Grid item xs={12}>
              <Paper elevation={2}>
                <TableContainer>
                  <Table size="small" aria-label="phones table">
                    <TableHead>
                      <TableRow>
                        {headers.map((header, index) => (
                          <TableCell key={index} style={{ fontWeight: "bold" }}>
                            {header}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {data.map((row, index) => (
                        <TableRow key={index} hover>
                          <TableCell>
                            {Intl.DateTimeFormat("no-NO", {
                              year: "numeric",
                              month: "numeric",
                              day: "numeric",
                              hour: "numeric",
                              minute: "numeric",
                              second: "numeric",
                            }).format(new Date(row.timestamp))}
                          </TableCell>
                          <TableCell>{row.tlf}</TableCell>
                          <TableCell>{row.system}</TableCell>
                          <TableCell>{row.isFromSms && row.isFromSms.toString()}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </Grid>

            {/* Pagination Section */}
            <Grid item xs={12}>
              <Pagination itemsCount={dataLength} pageSize={pageSize} currentPage={currentPage} phrase={searchQuery} onPageChange={this.handlePageChangeQuery} />
            </Grid>
          </Grid>
        )}
      </Container>
    );
  }
}

export default Phones;
