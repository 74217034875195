import React, { useRef, useState, useEffect, useCallback, useMemo } from "react";
import { TableContainer, Container, Grid, Paper, Typography, Table, TableHead, TableBody, TableRow, TableCell, Box, Button, Divider } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import Chart from "./common/Chart";
import ContainerDimensions from "react-container-dimensions";
import { TrailingChart, LifetimeChart, SubscriberTrendChart } from "./common/RechartsCharts";
import auth from "../services/authService";
import { getFlag } from "../utils/countriesWindows";
import styled from "styled-components";

// Styled components remain unchanged or adjusted as needed.
const TopBarContainer = styled(Box)`
  margin-bottom: 24px;
  width: fit-content;
  margin: 10px auto;
  padding: 8px;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  width: fit-content;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const Data = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 8px;
  border-radius: 4px;
  width: fit-content;
  margin: 0 auto;
  position: absolute;
  right: -8rem;
  top: 8px;
  z-index: 10;

  h6 {
    color: white !important;
  }
  p {
    color: white !important;
  }

  @media (max-width: 768px) {
    right: 20%;
    top: 6rem;
  }
`;

const Refresh = styled.button`
  margin-left: auto;
  display: flex;
  align-items: center;
  border: none;
  background: none;
  cursor: pointer;
  color: ${(props) => props.color || "inherit"};
  font-size: 1rem;

  &:hover {
    opacity: 0.8;
  }
`;

const RefreshIconStyled = styled(RefreshIcon)`
  width: 24px;
  height: 24px;
  margin-left: 0.5rem;
  animation: ${(props) => (props.spin ? "spin 1s linear infinite" : "none")};

  @keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }
`;

// You may continue using your existing ToggleButton styling if desired.
// Here we use MUI's Button for a consistent look.
const ToggleButton = styled(Button)`
  margin-top: 1rem;
`;

const DashboardTopBar = ({ refreshHome, animation, unsubs, isHomeRole }) => {
  const [showStats, setShowStats] = useState(false);

  const handleToggleStats = () => {
    setShowStats((prev) => !prev);
  };

  return (
    <TopBarContainer>
      <Refresh onClick={refreshHome} color="primary">
        <h2 style={{ margin: 0 }}>Dashboard</h2>
        <RefreshIconStyled spin={animation} />
      </Refresh>
      {!isHomeRole && (
        <ToggleButton variant="contained" className="bg-gray" onClick={handleToggleStats}>
          {showStats ? "Hide un-subs" : "Show un-subs"}
        </ToggleButton>
      )}
      {!isHomeRole && showStats && (
        <Data className="bg-gray">
          <Typography variant="h6">Un-subs</Typography>
          <Typography variant="body1">
            Today: <strong>{unsubs?.today}</strong>
          </Typography>
          <Typography variant="body1">
            Yesterday: <strong>{unsubs?.yesterday}</strong>
          </Typography>
          <Typography variant="body2">
            This month: <strong>{unsubs?.month}</strong>
          </Typography>
          <Typography variant="body2">
            Total: <strong>{unsubs?.total}</strong>
          </Typography>
        </Data>
      )}
    </TopBarContainer>
  );
};

const countries = ["no", "se", "dk", "fi", "nl", "de", "at", "be", "pl", "cz", "ie", "pt", "fr", "es"];

// DelayedTD and AnimatedTD remain unchanged.
const DelayedTD = ({ value }) => {
  const [renderedValue, setRenderedValue] = useState(value);

  useEffect(() => {
    if (!renderedValue) {
      setRenderedValue(value);
    } else {
      setTimeout(() => {
        setRenderedValue(value);
      }, 500);
    }
  }, [value]);

  return <>{renderedValue}</>;
};

const AnimatedTD = ({ value }) => {
  const [classNames, setClassnames] = useState("");
  const prevCountRef = useRef(value);

  useEffect(() => {
    if (prevCountRef.current !== value) {
      setClassnames("dashboard-data-column-animate");
      setTimeout(() => {
        setClassnames("");
      }, 500);
    }
    prevCountRef.current = value;
  }, [value]);

  return (
    <span className={classNames}>
      <DelayedTD value={value} />
    </span>
  );
};

const getDataTable = (newProducts, paramName) => {
  // Calculate totals per country and overall sum.
  const sums = countries.map((country) => newProducts.reduce((acc, value) => acc + value[country][paramName], 0));
  const sumAll = sums.reduce((a, b) => a + b, 0);

  return (
    <TableContainer component={Paper} sx={{ maxWidth: "100%", overflowX: "auto", mt: 1 }}>
      <Table size="small">
        <TableHead>
          <TableRow>
            {/* Row header (e.g., product name) */}
            <TableCell></TableCell>
            {/* Move ALL column to the first data column */}
            <TableCell align="center">ALL</TableCell>
            {/* Then list the country columns */}
            {countries.map((country) => (
              <TableCell padding="none" key={country} align="center">
                {getFlag(country)}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {[...newProducts]
            .sort((a, b) => (a.name || "").localeCompare(b.name || ""))
            .map((value, idx) => {
              // Skip any product that shouldn't be displayed.
              if (!value.name || value.name === "SeaTrue" || value.name === "Q22" || value.name === "BioBelly" || value.name === "Energy B12D3" || value.name === "FLEXYPRO")
                return null;
              return (
                <TableRow key={idx}>
                  {/* Product name column */}
                  <TableCell
                    padding="none"
                    sx={{
                      fontWeight: 600,
                      whiteSpace: "nowrap",
                      fontSize: "11px",
                      paddingLeft: "5px",
                    }}
                  >
                    {value.name}
                  </TableCell>
                  {/* ALL column placed first */}
                  <TableCell align="center" padding="none">
                    <DelayedTD value={value[paramName]} />
                  </TableCell>
                  {/* Then render each country column */}
                  {countries.map((country) => (
                    <TableCell padding="none" key={country} align="center">
                      <AnimatedTD value={value[country][paramName]} />
                    </TableCell>
                  ))}
                </TableRow>
              );
            })}
          {/* Totals row */}
          <TableRow>
            <TableCell padding="none" sx={{ fontWeight: 600, paddingLeft: "5px" }}>
              All
            </TableCell>
            {/* ALL total */}
            <TableCell align="center" padding="none">
              <DelayedTD value={sumAll} />
            </TableCell>
            {/* Totals for each country */}
            {sums.map((val, idx) => (
              <TableCell key={idx} align="center" padding="none">
                <DelayedTD sx={{ fontSize: "11px" }} value={val} />
              </TableCell>
            ))}
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

// --------------------
// Modified Home Component with Individual Toggles
// --------------------
const Home = React.memo(function Home({ home, refreshHome, animation }) {
  const [user, setUser] = useState(null);
  const [showTables, setShowTables] = useState({
    ordersToday: false,
    ordersYesterday: false,
    ordersMonth: false,
    ordersTotal: false,
    activeSubs: false,
  });

  useEffect(() => {
    const currentUser = auth.getCurrentUser();
    setUser(currentUser);
  }, []);

  const isMobile = useCallback(() => window.innerWidth < 768, []);

  const toggleTable = useCallback((tableKey) => {
    setShowTables((prev) => ({
      ...prev,
      [tableKey]: !prev[tableKey],
    }));
  }, []);

  const { orders, unsubs, churn, trailData, thisMonthAvg, thisMonthLabels, subData, accuSubs } = home;
  const { ordersToday, ordersYesterday, ordersMonth, ordersTotal, activeSubs, activeSubsUnsubed, products } = orders;

  const mobile = isMobile();
  const isHomeRole = user?.role === "home";

  const newProducts = useMemo(() => {
    const result = [];

    // Transform product data
    for (const product of products) {
      const country = product.country ?? "no";
      if (country === "ch") continue;

      let productName = "";
      if (product.name.includes("Melatonin Vital")) productName = "Melatonin Vital";
      else if (product.name.includes("Collagen Vital")) productName = "Collagen Vital";
      else if (product.name.includes("Hair And Skin")) productName = "Hair And Skin";
      else if (product.name.includes("Mory")) productName = "Mory";
      else if (product.name.includes("Energy B12D3")) productName = "Energy B12D3";
      else if (product.name.includes("Q22")) productName = "Q22";
      else if (product.name.includes("Craveless")) productName = "Craveless";
      else if (product.name.includes("T-Complex")) productName = "T-Complex";
      else if (product.name.includes("ProbiForte")) productName = "BioBelly";
      else if (product.name.includes("BioBelly")) productName = "BioBelly";
      else if (product.name.includes("SeaTrue")) productName = "SeaTrue";
      else if (product.name.includes("FLEXYPRO")) productName = "FLEXYPRO";
      else if (product.name.includes("SLEEPMORE")) productName = "SLEEPMORE";
      else if (product.name.includes("MemRY")) productName = "MemRY";
      else if (product.name.includes("Collagen Multi")) productName = "Collagen Multi";
      else if (product.name.includes("Mello17")) productName = "Mello17";
      else if (product.name.includes("Retine")) productName = "Retine";
      else if (product.name.includes("MEDINIGHT")) productName = "MEDINIGHT";
      else if (product.name.includes("hues Glow")) productName = "hues Glow";
      else if (product.name.includes("Hues Skin")) productName = "Hues Skin";
      else if (product.name.includes("Estrodoxin")) productName = "Estrodoxin";
      else if (product.name.includes("Enzytin")) productName = "Enzytin";
      else if (product.name.includes("Summit Magnesium")) productName = "Summit Magnesium";

      let mvIndex = result.findIndex((x) => x.name === productName);
      if (mvIndex === -1) {
        const dataObj = {
          name: productName,
          ordersToday: 0,
          ordersYesterday: 0,
          ordersMonth: 0,
          ordersTotal: 0,
          activeSubs: 0,
          activeSubsUnsubed: 0,
        };

        // Initialize country-specific data
        countries.forEach((country) => {
          dataObj[country] = {
            ordersToday: 0,
            ordersYesterday: 0,
            ordersMonth: 0,
            ordersTotal: 0,
            activeSubs: 0,
            activeSubsUnsubed: 0,
          };
        });

        result.push(dataObj);
        mvIndex = result.length - 1;
      }

      result[mvIndex][country].ordersToday += product.ordersToday;
      result[mvIndex][country].ordersYesterday += product.ordersYesterday;
      result[mvIndex][country].ordersMonth += product.ordersMonth;
      result[mvIndex][country].ordersTotal += product.ordersTotal;
      result[mvIndex][country].activeSubs += product.activeSubs;
      result[mvIndex][country].activeSubsUnsubed += product.activeSubsUnsubed;

      result[mvIndex].ordersToday += product.ordersToday;
      result[mvIndex].ordersYesterday += product.ordersYesterday;
      result[mvIndex].ordersMonth += product.ordersMonth;
      result[mvIndex].ordersTotal += product.ordersTotal;
      result[mvIndex].activeSubs += product.activeSubs;
      result[mvIndex].activeSubsUnsubed += product.activeSubsUnsubed;
    }

    return result;
  }, [products]);

  return (
    <Container maxWidth="1900px" sx={{ mb: 4 }}>
      <DashboardTopBar refreshHome={refreshHome} animation={animation} unsubs={unsubs} isHomeRole={isHomeRole} />

      <Grid container spacing={3} textAlign="center">
        {/* Orders Today */}
        <Grid item xs={12} md={6}>
          <Paper elevation={3}>
            <Box p={2}>
              <Typography variant="h6">Subs today</Typography>
              <Typography variant="h4" component="div" sx={{ fontWeight: 700 }}>
                {ordersToday}
              </Typography>
              {mobile && (
                <ToggleButton variant="outlined" onClick={() => toggleTable("ordersToday")}>
                  {showTables.ordersToday ? "Hide data" : "Show data"}
                </ToggleButton>
              )}
              {(!mobile || showTables.ordersToday) && getDataTable(newProducts, "ordersToday")}
            </Box>
          </Paper>
        </Grid>

        {/* Orders Yesterday */}
        <Grid item xs={12} md={6}>
          <Paper elevation={3}>
            <Box p={2}>
              <Typography variant="h6">Subs yesterday</Typography>
              <Typography variant="h4" component="div" sx={{ fontWeight: 700 }}>
                {ordersYesterday}
              </Typography>
              {mobile && (
                <ToggleButton variant="outlined" onClick={() => toggleTable("ordersYesterday")}>
                  {showTables.ordersYesterday ? "Hide data" : "Show data"}
                </ToggleButton>
              )}
              {(!mobile || showTables.ordersYesterday) && getDataTable(newProducts, "ordersYesterday")}
            </Box>
          </Paper>
        </Grid>

        {/* Orders This Month */}
        <Grid item xs={12} md={6}>
          <Paper elevation={3}>
            <Box p={2}>
              <Typography variant="h6">Subs this month</Typography>
              <Typography variant="h4" component="div" sx={{ fontWeight: 700 }}>
                {ordersMonth}
              </Typography>
              {mobile && (
                <ToggleButton variant="outlined" onClick={() => toggleTable("ordersMonth")}>
                  {showTables.ordersMonth ? "Hide data" : "Show data"}
                </ToggleButton>
              )}
              {(!mobile || showTables.ordersMonth) && getDataTable(newProducts, "ordersMonth")}
            </Box>
          </Paper>
        </Grid>

        {/* Orders Total */}
        <Grid item xs={12} md={6}>
          <Paper elevation={3}>
            <Box p={2}>
              <Typography variant="h6">Subs total</Typography>
              <Typography variant="h4" component="div" sx={{ fontWeight: 700 }}>
                {ordersTotal}
              </Typography>
              {mobile && (
                <ToggleButton variant="outlined" onClick={() => toggleTable("ordersTotal")}>
                  {showTables.ordersTotal ? "Hide data" : "Show data"}
                </ToggleButton>
              )}
              {(!mobile || showTables.ordersTotal) && getDataTable(newProducts, "ordersTotal")}
            </Box>
          </Paper>
        </Grid>
      </Grid>

      {/* Active Subs Total */}
      <Grid item xs={12} sx={{ mt: 2 }} md={isHomeRole ? 6 : 4}>
        <Paper elevation={3}>
          <Box p={2} textAlign="center">
            <Typography variant="h6">Active subs total</Typography>
            <Typography variant="h4" component="div" sx={{ fontWeight: 700 }}>
              {activeSubs} (-{activeSubsUnsubed})
            </Typography>
            {mobile && (
              <ToggleButton variant="outlined" onClick={() => toggleTable("activeSubs")}>
                {showTables.activeSubs ? "Hide data" : "Show data"}
              </ToggleButton>
            )}
            {(!mobile || showTables.activeSubs) && getDataTable(newProducts, "activeSubs")}
          </Box>
        </Paper>
      </Grid>

      {/* Charts */}
      <Grid container spacing={3} sx={{ mt: 2 }}>
        <Grid item xs={12}>
          <Paper elevation={3}>
            <Box p={2} textAlign="center">
              <Typography variant="h6">Trailing 30 days</Typography>
              <Box sx={{ mt: 2 }}>
                <ContainerDimensions>
                  {({ width }) => <TrailingChart data={trailData} avg={thisMonthAvg} avgLabel={thisMonthLabels} width={width} height={300} />}
                </ContainerDimensions>
              </Box>
            </Box>
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Paper elevation={3}>
            <Box p={2} textAlign="center">
              <Typography variant="h6">Lifetime orders</Typography>
              <Box sx={{ mt: 2 }}>
                <ContainerDimensions>{({ width }) => <LifetimeChart data={subData} width={width} height={300} />}</ContainerDimensions>
              </Box>
            </Box>
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Paper elevation={3}>
            <Box p={2} textAlign="center">
              <Typography variant="h6">Subscriber trend</Typography>
              <Box sx={{ mt: 2 }}>
                <ContainerDimensions>{({ width }) => <SubscriberTrendChart data={accuSubs} width={width} height={300} />}</ContainerDimensions>
              </Box>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
});

export default Home;
