import React from "react";
import { Row, Col } from "react-bootstrap";
import SearchBox from "./SearchBox";
import { Button } from "@mui/material";

const PageHeader = ({ pageName, sorted, count, totalCount, searchQuery, onSearch, onSearchSubmit, onNew, hasSearch, selectedFilter, loading }) => {
  return (
    <React.Fragment>
      <Row>
        <Col>
          <h1>{pageName}s</h1>
          {loading ? (
            <h3>Loading...</h3>
          ) : (
            <h3>
              Viewing {count} of {totalCount} {pageName.toLowerCase()}(s).
            </h3>
          )}
        </Col>
      </Row>
      {hasSearch && (
        <Row>
          <Col className="col-sm-8 col-lg-3 my-3">
            <SearchBox value={searchQuery} onChange={onSearch} onSearchSubmit={onSearchSubmit} selectedFilter={selectedFilter} />
          </Col>
        </Row>
      )}
      {/* Commenting out if we want to use gain in the future */}
      <Row>
        <Col className="mb-3">
          {onNew && (
            <Button className="bg-blue" onClick={onNew}>
              New {pageName.toLowerCase()}
            </Button>
          )}
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default PageHeader;
