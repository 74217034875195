import React, { Component } from "react";
import { ButtonGroup, Spinner } from "react-bootstrap";
import Modal from "react-modal";
import Checkbox from "./CheckBox";
import styled from "styled-components";

const Label = styled.label`
  margin-left: 8px;
  margin-top: 18px;
`;

const Container = styled(Modal)`
  position: absolute;
  top: 50;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, 50%);
  background-color: var(--bg-color-light);
  color: var(--text-color-light);
  padding: 20px;
  border: 1px solid #ccc;
`;

Modal.setAppElement("#root");

class Dialog extends Component {
  render() {
    return (
      <Container isOpen={this.props.open} onAfterOpen={this.afterOpenModal} onRequestClose={this.props.close} shouldCloseOnOverlayClick={true}>
        <h2>{this.props.title}</h2>
        <div className="mb-3">{this.props.text}</div>
        {this.props.reasons && (
          <div className="form-group">
            <label htmlFor="reasons">Reason for unsub</label>
            <select className="form-control" value={this.props.reasons[this.props.reason]} onChange={this.props.handleDropdownChange} name="reasons">
              {this.props.reasons.map((value, index) => (
                <option value={index} key={index}>
                  {value.reason}
                </option>
              ))}
            </select>

            <div style={{ display: "flex", alignItems: "center", marginTop: "8px" }}>
              <Checkbox id="exempt" name="exempt" checked={this.props.checkboxChecked} onChange={this.props.handleCheckboxChange} />
              <Label htmlFor="exempt">Unsub immediately (doesn't consider deadline)</Label>
            </div>

            <div style={{ display: "flex", alignItems: "center", marginTop: "8px" }}>
              <Checkbox id="winBack" name="winBack" checked={this.props.winBackChecked} onChange={this.props.handleWinBackChange} />
              <Label htmlFor="winBack">Winback attempted</Label>
            </div>
          </div>
        )}
        {this.props.sms && <div>SMS FORM</div>}
        <ButtonGroup>
          <button className="btn btn-danger mr-3" onClick={this.props.doYes} disabled={this.props.loading}>
            {this.props.loading && <Spinner animation="border" size="sm" />}
            {this.props.yes}
          </button>
          <button className="btn btn-secondary" onClick={this.props.doNo} disabled={this.props.loading}>
            {this.props.no}
          </button>
        </ButtonGroup>
      </Container>
    );
  }
}

export default Dialog;
