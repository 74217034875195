import { getCustomers, getTotalCustomersCount } from "../services/customerService";
import { getProductFilters } from "../services/productService";
import React, { useEffect, useState, useRef, useContext } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  TablePagination,
  InputAdornment,
  IconButton,
  Autocomplete,
  Grid,
  TableSortLabel, // <-- MUI's sort label
  Tooltip,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import date from "date-and-time";
import authService from "../services/authService";
import { getCountries } from "../services/userService";
import styled from "styled-components";
import { getFlag } from "../utils/countries";
import { SearchContext, SearchDispatchContext } from "./search.context";

const Container = styled.div`
  padding: 16px;
`;

const CustomersMUI = (props) => {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [totalRows, setTotalRows] = useState(1);
  const [countries, setCountries] = useState([]);
  const [sources] = useState(["FACEBOOK", "GOOGLE", "ORION", "TELE", "OTHER", "TIKTOK", "SNAPCHAT"]);
  const [saleTypes] = useState(["All", "Subs", "Singles"]);
  const [filters, setFilters] = useState([]);

  const [sortField, setSortField] = useState("lastSubCreated"); // 'created', 'name', etc.
  const [sortDirection, setSortDirection] = useState("asc"); // 'asc' or 'desc'

  // activeFilters come from context so we remember the last search across routes
  const activeFilters = useContext(SearchContext);
  const dispatch = useContext(SearchDispatchContext);

  // Table columns: either a string (not sortable) or an object with { label, field } (sortable).
  const headers = [
    "Flag",
    { label: "Latest sub", field: "lastSubCreated" },
    "Id",
    { label: "Name", field: "name" },
    { label: "Street", field: "street" },
    "Country",
    { label: "Email", field: "email" },
    "Phone",
    "Active Subs",
    "Products",
  ];

  // Fetch data whenever the pagination, sorting, or search filters change
  const fetchData = async () => {
    const { data } = await getCustomers({
      page: currentPage,
      pagination: pageSize,
      phrase: activeFilters.searchQuery,
      productId: activeFilters.product,
      country: activeFilters.country.id,
      source: activeFilters.source,
      saleType: activeFilters.saleType,
      sortField,
      sortDirection,
    });

    setData(data);

    // Count total rows for pagination
    const { length } = await getTotalCustomersCount({
      phrase: activeFilters.searchQuery,
      productId: activeFilters.product,
      country: activeFilters.country.id,
      source: activeFilters.source,
      saleType: activeFilters.saleType,
    });
    setTotalRows(Math.ceil(length));

    // Load product filters
    let filterList = await getProductFilters(authService.getCurrentUser()._id);
    filterList.unshift("All");
    setFilters(filterList);

    // Load countries
    let c = await getCountries(authService.getCurrentUser()._id);
    c.unshift({ code: "All", nameEng: "All" });
    setCountries(c);
  };

  useEffect(() => {
    if (authService.hasRole(["admin", "support"])) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, pageSize, sortField, sortDirection, activeFilters]);

  // MUI pagination handlers
  const handlePageChange = (event, value) => {
    setCurrentPage(value + 1);
  };
  const handleChangeRowsPerPage = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setCurrentPage(1);
  };

  // For text search
  const typedSearchQuery = useRef("");
  const [key, setKey] = useState(Math.random());

  const handleSearchKeyDown = (e) => {
    if (e.key === "Enter") {
      dispatch({ type: "change", filter: "searchQuery", value: e.target.value });
      setCurrentPage(1);
    }
  };
  const handleSearchChange = (e) => {
    typedSearchQuery.current = e.target.value;
  };
  const handleCloseIconClick = () => {
    typedSearchQuery.current = "";
    dispatch({ type: "change", filter: "searchQuery", value: "" });
    dispatch({ type: "change", filter: "product", value: "All" });
    dispatch({ type: "change", filter: "country", value: "All" });
    dispatch({ type: "change", filter: "saleType", value: "All" });
    dispatch({ type: "change", filter: "source", value: "All" });
    setCurrentPage(1);
    setKey(Math.random()); // Force re-render of the TextField
  };

  // Sorting
  const handleSortChange = (field) => {
    if (sortField === field) {
      // If already sorting by this field, toggle the direction
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      // Otherwise, set the new field and reset to ascending
      setSortField(field);
      setSortDirection("asc");
    }
  };

  // Edit (click row)
  const editCustomer = (customer, newWindow) => {
    const host = window.location.protocol + "//" + window.location.host;
    const path = "/customers/" + customer._id;
    if (!newWindow) {
      props.history.push(path);
    } else {
      window.open(host + path, "_blank");
    }
  };

  return (
    <div>
      {/* Filters/Search */}
      <div style={{ margin: "20px" }}>
        <Grid container spacing={2}>
          {/* Search Field */}
          <Grid item xs={12} md={3}>
            <Tooltip
              title={
                <span>
                  Using : in front of address to search for IP address
                  <br />
                  Using # for invoice number
                  <br />
                  using r: for Riverty invoice number
                </span>
              }
              placement="top"
              arrow
            >
              <TextField
                fullWidth
                key={key} // Force re-render when clearing search
                label="Search"
                variant="outlined"
                defaultValue={activeFilters.searchQuery?.length > 0 ? activeFilters.searchQuery : typedSearchQuery.current}
                onChange={handleSearchChange}
                onKeyDown={handleSearchKeyDown}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton edge="end" onClick={handleCloseIconClick}>
                        <CloseIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Tooltip>
          </Grid>

          {/* Product Filter */}
          <Grid item xs={12} md={3}>
            <Autocomplete
              fullWidth
              options={filters.map((series) => ({ id: series, label: series }))}
              renderInput={(params) => <TextField {...params} label="Product" />}
              value={activeFilters.product}
              onChange={(event, value) => {
                if (value) {
                  dispatch({ type: "change", filter: "product", value: value.id });
                } else {
                  dispatch({ type: "change", filter: "product", value: "All" });
                }
              }}
            />
          </Grid>

          {/* Country Filter */}
          <Grid item xs={12} md={3}>
            <Autocomplete
              fullWidth
              options={countries.map((country) => ({
                id: country.code + "",
                label: country.nameEng,
              }))}
              renderInput={(params) => <TextField {...params} label="Country" />}
              value={activeFilters.country}
              onChange={(event, value) => {
                if (value) {
                  dispatch({ type: "change", filter: "country", value: value });
                } else {
                  dispatch({ type: "change", filter: "country", value: "All" });
                }
              }}
            />
          </Grid>

          {/* Sale Type Filter */}
          <Grid item xs={12} md={3}>
            <Autocomplete
              fullWidth
              options={saleTypes.map((source) => ({ id: source, label: source }))}
              renderInput={(params) => <TextField {...params} label="Sale Types" />}
              value={activeFilters.saleType}
              onChange={(event, value) => {
                if (value) {
                  dispatch({ type: "change", filter: "saleType", value: value.id });
                } else {
                  dispatch({ type: "change", filter: "saleType", value: "All" });
                }
              }}
            />
          </Grid>

          {/* Source Filter (for admin only) */}
          {authService.hasRole(["admin"]) && (
            <Grid item xs={12} md={3}>
              <Autocomplete
                fullWidth
                options={sources.map((source) => ({ id: source, label: source }))}
                renderInput={(params) => <TextField {...params} label="Sources" />}
                value={activeFilters.source}
                onChange={(event, value) => {
                  if (value) {
                    dispatch({ type: "change", filter: "source", value: value.id });
                  } else {
                    dispatch({ type: "change", filter: "source", value: "All" });
                  }
                }}
              />
            </Grid>
          )}
        </Grid>
      </div>

      {/* Table */}
      <Container>
        <Paper>
          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow style={{ backgroundColor: "rgba(0, 0, 0, 0.2)" }}>
                  {headers.map((header, index) => {
                    let label;
                    let field;
                    // If header is just a string (non-sortable column)
                    if (typeof header === "string") {
                      label = header;
                    } else {
                      // Otherwise, we can sort by this column
                      label = header.label;
                      field = header.field;
                    }

                    const isSorted = sortField === field;
                    const direction = isSorted ? sortDirection : "asc"; // default "asc" for visual

                    return (
                      <TableCell key={index} style={{ fontWeight: "bold" }}>
                        {field ? (
                          // Use MUI's TableSortLabel to show direction & handle clicks
                          <TableSortLabel active={isSorted} direction={direction} onClick={() => handleSortChange(field)}>
                            {label}
                          </TableSortLabel>
                        ) : (
                          // If not sortable, just render label
                          label
                        )}
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>

              <TableBody>
                {data.map((customer, index) => {
                  // Calculate Active Subs & Product Text
                  let activeSubs = 0;
                  let productText = "";
                  if (customer.subs) {
                    for (const key in customer.subs) {
                      if (customer.subs[key].active) {
                        activeSubs++;
                        const isLast = key === String(customer.subs.length - 1);
                        productText += customer.subs[key].product.name + (isLast ? "" : ", ");
                      }
                    }
                  }
                  // Single Sales (orders)
                  let singleSales = 0;
                  if (customer.orders && customer.orders.length >= 1) {
                    singleSales = customer.orders.length;
                  }

                  // Row background stripes
                  const bgColor = index % 2 === 0 ? "rgba(0, 0, 0, 0.02)" : "rgba(0, 0, 0, 0.12)";

                  return (
                    <TableRow
                      key={customer._id}
                      style={{ backgroundColor: bgColor, cursor: "pointer" }}
                      onClick={(e) => {
                        const newWindow = e.ctrlKey || e.metaKey;
                        editCustomer(customer, newWindow);
                      }}
                    >
                      {/* Flag */}
                      <TableCell>{customer.flags?.[customer.flags.length - 1]?.flagged ? <i className="fas fa-flag fa-2x red"></i> : ""}</TableCell>

                      {/* Created */}
                      <TableCell>{date.format(new Date(customer.lastSubCreated), "DD/MM/YYYY, HH:mm:ss")}</TableCell>

                      {/* ID */}
                      <TableCell>{customer.id}</TableCell>

                      {/* Name */}
                      <TableCell>{customer.name}</TableCell>

                      {/* Street */}
                      <TableCell>{customer.street}</TableCell>

                      {/* Country */}
                      <TableCell>{getFlag(customer.country)}</TableCell>

                      {/* Email */}
                      <TableCell>{customer.email}</TableCell>

                      {/* Phone */}
                      <TableCell>{customer.phone}</TableCell>

                      {/* Active Subs */}
                      <TableCell>{activeSubs}</TableCell>

                      {/* Products */}
                      <TableCell>{productText}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>

        {/* Pagination */}
        <TablePagination
          component="div"
          count={totalRows}
          page={currentPage - 1}
          onPageChange={handlePageChange}
          rowsPerPage={pageSize}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[5, 10, 25, 50, 100]}
        />
      </Container>
    </div>
  );
};

export default CustomersMUI;
