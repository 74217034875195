import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider, CssBaseline } from "@mui/material";
import { lightTheme, darkTheme } from "./components/common/Theme";

function Root() {
  const [themeMode, setThemeMode] = useState(localStorage.getItem("theme") || "light");

  useEffect(() => {
    localStorage.setItem("theme", themeMode);
  }, [themeMode]);

  const toggleTheme = () => {
    setThemeMode((prev) => (prev === "light" ? "dark" : "light"));
  };

  const appliedTheme = themeMode === "light" ? lightTheme : darkTheme;

  return (
    <ThemeProvider theme={appliedTheme}>
      <CssBaseline />

      <div className={themeMode}>
        <BrowserRouter>
          <App toggleTheme={toggleTheme} currentTheme={themeMode} />
        </BrowserRouter>
      </div>
    </ThemeProvider>
  );
}

ReactDOM.render(<Root />, document.getElementById("root"));
