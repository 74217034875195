import React from "react";
import PageTable from "./common/PageTable";
import PageHeader from "./common/PageHeader";
import Pagination from "./common/Pagination";
import { getBlacklist, countBlacklist, deleteItem, addItem } from "../services/blacklistService";
import { toast } from "react-toastify";
import authService from "../services/authService";
import date from "date-and-time";
import _ from "lodash";

// MUI imports
import {
  TableRow,
  TableCell,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  TextField,
  Button, 
  Grid,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  IconButton,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import styled from "styled-components";

const Container = styled.div`
  padding: 16px;
`;

function NewBacklog(props) {
  const { newBacklog, updateField, add, cancel, load } = props;

  return (
    <TableRow>
      {/* FIELD Select */}
      <TableCell>
        <FormControl fullWidth size="small">
          <InputLabel>Field</InputLabel>
          <Select label="Field" value={newBacklog["field"]} onChange={(e) => updateField("field", e.target.value)}>
            <MenuItem value="personid">Person ID</MenuItem>
            <MenuItem value="name">Name</MenuItem>
            <MenuItem value="street">Street</MenuItem>
            <MenuItem value="phone">Phone</MenuItem>
            <MenuItem value="affId">Affiliate ID</MenuItem>
            <MenuItem value="postcode">Postal Code</MenuItem>
            <MenuItem value="postarea">Area</MenuItem>
            <MenuItem value="email">Email</MenuItem>
            <MenuItem value="ip">IP Address</MenuItem>
          </Select>
        </FormControl>
      </TableCell>

      {/* EXACT Checkbox */}
      <TableCell>
        <Checkbox size="small" checked={newBacklog["exact"]} onChange={(e) => updateField("exact", e.target.checked)} />
      </TableCell>

      {/* VALUE TextField */}
      <TableCell>
        <TextField label="Value" variant="outlined" size="small" fullWidth value={newBacklog["value"]} onChange={(e) => updateField("value", e.target.value)} />
      </TableCell>

      {/* COUNTRY Select */}
      <TableCell>
        <FormControl fullWidth size="small">
          <InputLabel>Country</InputLabel>
          <Select label="Country" value={newBacklog["country"]} onChange={(e) => updateField("country", e.target.value)}>
            <MenuItem value="no">Norway</MenuItem>
            <MenuItem value="se">Sweden</MenuItem>
            <MenuItem value="dk">Denmark</MenuItem>
            <MenuItem value="nl">Netherlands</MenuItem>
            <MenuItem value="de">Germany</MenuItem>
          </Select>
        </FormControl>
      </TableCell>

      {/* CREATED (empty cell for alignment) */}
      <TableCell />

      {/* ACTIONS Cell */}
      <TableCell>
        <Button variant="contained" className="bg-blue" size="small" onClick={add} disabled={load} sx={{ marginRight: 1 }}>
          Add
        </Button>
        <Button variant="contained" className="bg-orange" size="small" onClick={cancel}>
          Cancel
        </Button>
      </TableCell>
    </TableRow>
  );
}

class Blacklist extends PageTable {
  state = {
    newBacklog: { field: "name", exact: true, value: "", country: "no" },
    data: [],
    dataLength: 0,
    totalLength: 0,
    pageSize: 10,
    currentPage: 1,
    searchQuery: "",
    loading: true,
    phrase: "",
    newOpen: false,
    addNewLoad: false,
  };

  async componentDidMount() {
    if (authService.hasRole(["admin"])) {
      const { data } = await getBlacklist({
        page: this.state.currentPage,
        pagination: this.state.pageSize,
        phrase: this.state.phrase,
      });

      const { length } = await countBlacklist();

      this.setState({
        totalLength: length,
        data,
        dataLength: length,
        loading: false,
      });
    }
  }

  handleSearchChange = (searchQuery) => {
    this.setState({ searchQuery });
  };

  handleNewValueChange = (name, value) => {
    const newBacklog = { ...this.state.newBacklog, [name]: value };
    this.setState({ newBacklog });
  };

  handleAddNew = async () => {
    this.setState({ addNewLoad: true });
    try {
      await addItem(this.state.newBacklog);

      // Refresh the list after adding
      const { data } = await getBlacklist({
        page: this.state.currentPage,
        pagination: this.state.pageSize,
        phrase: this.state.phrase,
      });
      const { length } = await countBlacklist();

      this.setState({
        addNewLoad: false,
        totalLength: length,
        data,
        dataLength: length,
        newBacklog: { field: "name", exact: true, value: "", country: "no" },
        newOpen: false,
        searchQuery: "",
      });
    } catch (err) {
      toast.error("Failed to add blacklist item.");
      this.setState({ addNewLoad: false });
    }
  };

  handleSearchSubmit = async (page, phrase) => {
    if (authService.hasRole(["admin"])) {
      const { data } = await getBlacklist({
        page,
        pagination: this.state.pageSize,
        phrase,
      });
      const { length } = await countBlacklist();

      // If a phrase is provided, set dataLength to data.length, else to total
      if (phrase) {
        this.setState({
          data,
          dataLength: data.length,
          searchQuery: phrase,
        });
      } else {
        this.setState({
          data,
          dataLength: length,
          searchQuery: "",
        });
      }
    }
  };

  handlePageChangeQuery = async (page, phrase) => {
    if (authService.hasRole(["admin"])) {
      this.setState({ loading: true });
      const { data } = await getBlacklist({
        page,
        pagination: this.state.pageSize,
        phrase,
      });
      this.setState({
        phrase,
        currentPage: page,
        data,
        loading: false,
      });
    }
  };

  showNew = () => {
    if (authService.hasRole(["admin"])) {
      this.setState({ newOpen: true });
    }
  };

  hideNew = () => {
    if (authService.hasRole(["admin"])) {
      this.setState({ newOpen: false });
    }
  };

  delete = async (id) => {
    if (!authService.hasRole(["admin"])) return;

    try {
      await deleteItem({ _id: id });
      const { data } = await getBlacklist({
        page: this.state.currentPage,
        pagination: this.state.pageSize,
        phrase: this.state.phrase,
      });
      const { length } = await countBlacklist();

      this.setState({
        totalLength: length,
        data,
        dataLength: data.length,
      });
      toast.success("Deleted blacklist item.");
    } catch (err) {
      toast.error("Something failed while deleting blacklist item.");
    }
  };

  render() {
    const { data, pageSize, currentPage, phrase, loading, dataLength, totalLength, searchQuery, newOpen, newBacklog, addNewLoad } = this.state;

    const pageName = "Blacklist";
    const headers = ["Field", "Exact", "Value", "Country", "Created", ""];

    return (
      <Container>
        <PageHeader
          hasSearch="true"
          onSearch={this.handleSearchChange}
          onSearchSubmit={this.handleSearchSubmit}
          searchQuery={searchQuery}
          pageName={pageName}
          sorted={data}
          count={dataLength}
          totalCount={totalLength}
          onNew={this.showNew}
          loading={loading}
          selectedFilter={null}
        />

        {!loading && (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Paper elevation={2}>
                <TableContainer>
                  {/* Material UI Table */}
                  <Table size="small" aria-label="blacklist table">
                    <TableHead>
                      <TableRow>
                        {headers.map((header, index) => (
                          <TableCell key={index} style={{ fontWeight: "bold" }}>
                            {header}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {/* Conditionally render NEW row if newOpen */}
                      {newOpen && <NewBacklog newBacklog={newBacklog} updateField={this.handleNewValueChange} cancel={this.hideNew} add={this.handleAddNew} load={addNewLoad} />}

                      {/* Existing blacklist items */}
                      {data.map((row, index) => (
                        <TableRow key={index}>
                          <TableCell>{row.field}</TableCell>
                          <TableCell>{row.exact.toString()}</TableCell>
                          <TableCell>{row.value}</TableCell>
                          <TableCell>{row.country}</TableCell>
                          <TableCell>{date.format(new Date(row.created), "DD-MM-YYYY HH:MM:ss")}</TableCell>
                          <TableCell>
                            {/* Using MUI IconButton + DeleteIcon, or font-awesome if you prefer */}
                            <IconButton className="trash-icon" onClick={() => this.delete(row._id)} title="Delete">
                              <DeleteIcon />
                            </IconButton>
                            {/* Or if you want to keep Font Awesome:
                              <i
                                className="fas fa-times fa-2x pointer red"
                                onClick={() => this.delete(row._id)}
                              ></i>
                            */}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </Grid>

            {/* Pagination Section */}
            <Grid item xs={12}>
              <Pagination itemsCount={dataLength} pageSize={pageSize} currentPage={currentPage} phrase={phrase} onPageChange={this.handlePageChangeQuery} selectedFilter={null} />
            </Grid>
          </Grid>
        )}
      </Container>
    );
  }
}

export default Blacklist;
