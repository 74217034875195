import auth from "./services/authService";
import React, { Component, useReducer, useSate } from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { Container } from "react-bootstrap";
import { getReports, getReportsUser } from "./services/reportsService";
import { postRefresh } from "./services/userService";
import _ from "lodash";

// Necessary components
import LoginForm from "./components/LoginForm";
import Logout from "./components/Logout";
import Menu from "./components/Menu";
import Home from "./components/Home";
import Dash from "./components/Dash";
import EuropeMap from "./components/maps/EuropeMap";

import ShipmentBatches from "./components/ShipmentBatches";
import EditShipment from "./components/EditShipment";

//import Logs from "./components/Logs";

// Customers
import CustomersMUI from "./components/CustomersMUI";
import EditCustomer from "./components/EditCustomer";
import EditSub from "./components/EditSub";
import EditOrder from "./components/EditOrder";
import ClickCount from "./components/ClickCount";
import Auth from "./components/common/Auth";
import Blacklist from "./components/Blacklist";
import Phones from "./components/Phones";

// Articles
import ArticleList from "./components/articles/articleList";
import Inbox from "./components/inbox/inbox";
import CreateArticle from "./components/articles/createArticle";

// AI inbox
import authService from "./services/authService";

import "./App.css";
import "bootstrap/dist/css/bootstrap.css";
import "react-toastify/dist/ReactToastify.css";

import socketIOClient from "socket.io-client";

import runtimeEnv from "@mars/heroku-js-runtime-env";
import NewShipment from "./components/NewShipment";
import ByrdStock from "./components/ByrdStock";
import WepackStock from "./components/WepackStock";
import Testsales from "./components/Testsales";

import { SearchContext, SearchDispatchContext, searchReducer } from "./components/search.context";

const env = runtimeEnv();
class App extends Component {
  state = {
    animation: false,
    theme: "light",
    home: {
      chartData: [],
      thisMonthAvg: [],
      thisMonthLabels: [],
      trailData: [],
      subData: [],
      accuSubs: [],
      unsubs: {
        today: 0,
        yesterday: 0,
        month: 0,
        total: 0,
      },
      churn: {
        today: 0,
        yesterday: 0,
        month: 0,
        total: 0,
      },
      orders: {
        ordersToday: 0,
        ordersYesterday: 0,
        ordersMonth: 0,
        ordersTotal: 0,
        activeSubs: 0,
        products: [],
      },
    },
    customers: {
      selectedFilter: null,
      currentPage: 1,
      searchQuery: "",
    },
  };

  refreshHome = () => {
    const user = auth.getCurrentUser();
    const savedTheme = localStorage.getItem("theme") || "light";
    this.setState({ user, theme: savedTheme });
    getReportsUser({ username: this.state.user.name }).then(async (home) => {
      for (const trailKey in home.trailData) {
        home.trailData[trailKey].x = new Date(home.trailData[trailKey].x);
      }
      for (const chartKey in home.chartData) {
        home.chartData[chartKey].x = new Date(home.chartData[chartKey].x);
      }
      for (const accuKey in home.accuSubs) {
        home.accuSubs[accuKey].x = new Date(home.accuSubs[accuKey].x);
      }
      for (const subKey in home.subData) {
        home.subData[subKey].x = new Date(home.subData[subKey].x);
      }
      for (const avgKey in home.thisMonthAvg) {
        home.thisMonthAvg[avgKey].x = new Date(home.thisMonthAvg[avgKey].x);
      }
      for (const labelKey in home.thisMonthLabels) {
        home.thisMonthLabels[labelKey].x = new Date(home.thisMonthLabels[labelKey].x);
      }
      // Update state immutably to preserve component state
      this.setState((prevState) => ({
        home: {
          ...prevState.home,
          ...home,
          orders: {
            ...prevState.home.orders,
            ...home.orders,
          },
        },
        animation: false,
      }));
      postRefresh(this.state.user);
    });
  };

  async componentDidMount() {
    const user = auth.getCurrentUser();
    if (user) this.setState({ user });

    if (auth.hasRole("admin") || auth.hasRole("home")) {
      if (user) {
        const socket = socketIOClient(env.REACT_APP_API, {
          path: "/socket.io-client",
          transports: ["websocket"],
        });
        socket.on("report", async (report) => {
          let home = report.home;
          for (const trailKey in home.trailData) {
            home.trailData[trailKey].x = new Date(home.trailData[trailKey].x);
          }
          for (const chartKey in home.chartData) {
            home.chartData[chartKey].x = new Date(home.chartData[chartKey].x);
          }
          for (const accuKey in home.accuSubs) {
            home.accuSubs[accuKey].x = new Date(home.accuSubs[accuKey].x);
          }
          for (const subKey in home.subData) {
            home.subData[subKey].x = new Date(home.subData[subKey].x);
          }
          for (const avgKey in home.thisMonthAvg) {
            home.thisMonthAvg[avgKey].x = new Date(home.thisMonthAvg[avgKey].x);
          }
          for (const labelKey in home.thisMonthLabels) {
            home.thisMonthLabels[labelKey].x = new Date(home.thisMonthLabels[labelKey].x);
          }
          // Update state immutably to preserve component state
          this.setState((prevState) => ({
            home: {
              ...prevState.home,
              ...home,
              orders: {
                ...prevState.home.orders,
                ...home.orders,
              },
            },
          }));
        });

        getReports().then(async (home) => {
          for (const trailKey in home.trailData) {
            home.trailData[trailKey].x = new Date(home.trailData[trailKey].x);
          }
          for (const chartKey in home.chartData) {
            home.chartData[chartKey].x = new Date(home.chartData[chartKey].x);
          }
          for (const accuKey in home.accuSubs) {
            home.accuSubs[accuKey].x = new Date(home.accuSubs[accuKey].x);
          }
          for (const subKey in home.subData) {
            home.subData[subKey].x = new Date(home.subData[subKey].x);
          }
          for (const avgKey in home.thisMonthAvg) {
            home.thisMonthAvg[avgKey].x = new Date(home.thisMonthAvg[avgKey].x);
          }
          for (const labelKey in home.thisMonthLabels) {
            home.thisMonthLabels[labelKey].x = new Date(home.thisMonthLabels[labelKey].x);
          }
          // Update state immutably to preserve component state
          this.setState((prevState) => ({
            home: {
              ...prevState.home,
              ...home,
              orders: {
                ...prevState.home.orders,
                ...home.orders,
              },
            },
          }));
        });
      }
    }
  }

  handleStateChange = (data) => {
    this.setState(data);
  };

  render() {
    const { toggleTheme, currentTheme } = this.props; // Destructure here

    const products = this.state.home.orders.products;

    // Determine which component to render based on auth
    const renderRootComponent = (props) => {
      if (!authService.getCurrentUser()) {
        return <LoginForm {...props} />;
      }
      if (authService.hasRole("admin") || authService.hasRole("home")) {
        return <Home home={this.state.home} refreshHome={this.refreshHome} animation={this.state.animation} {...props} />;
      }
      if (authService.hasRole("support")) {
        return <CustomersMUI {...props} />;
      }
      if (authService.hasRole("author")) {
        return <ArticleList {...props} />;
      }
      return <LoginForm {...props} />;
    };

    return (
      <SearchContextWrapper>
        <ToastContainer />
        <Switch>
          <Route path="/map" render={(props) => <EuropeMap products={products} {...props} />} />
          <Route path="/dash" render={(props) => <Dash {...props} />} />
          <Route>
            <Menu toggleTheme={toggleTheme} currentTheme={currentTheme} />
            <Container fluid>
              <Switch>
                <Route path="/testsales" render={(props) => <Testsales {...props} />} />
                <Route path="/blacklist" render={(props) => <Blacklist {...props} />} />
                <Route path="/logout" render={(props) => <Logout {...props} />} />
                <Route path="/login" render={(props) => <LoginForm {...props} />} />
                {/* <Route path="/logs" render={(props) => <Logs {...props} />} /> */}
                <Route path="/counter" render={(props) => <ClickCount user={this.state.user} {...props} />} />
                <Route path="/customers/:customerid/subs/:subid/orders/:orderid" render={(props) => <EditOrder user={this.state.user} {...props} />} />
                <Route path="/customers/:customerid/subs/:subid" render={(props) => <EditSub updateCustomerSubData={this.updateCustomerSubData} {...props} />} />
                <Route
                  path="/customers/:customerid"
                  render={(props) => (
                    <EditCustomer
                      subs={this.state.subs}
                      customers={this.state.customers.data}
                      updateCustomerData={this.updateCustomerData}
                      deleteCustomerData={this.deleteCustomerData}
                      updateCustomerSubData={this.updateCustomerSubData}
                      {...props}
                    />
                  )}
                />
                <Route path="/customers" render={(props) => <CustomersMUI {...props} />} />
                <Route
                  path="/phones"
                  render={(props) => (
                    <Auth roles="admin">
                      <Phones {...props} />
                    </Auth>
                  )}
                />
                <Route
                  path="/inbox"
                  render={(props) => (
                    <Auth roles={["support", "admin"]}>
                      <Inbox {...props} />
                    </Auth>
                  )}
                />
                <Route path="/articles/articleList" render={(props) => <ArticleList {...props} />} />
                <Route path="/articles/CreateArticle" render={(props) => <CreateArticle {...props} />} />
                <Route path="/articles/CreateArticle/:id?" component={CreateArticle} />
                <Route path="/shipments/new2" render={(props) => <NewShipment {...props} />} />
                <Route path="/shipments/:shipmentId" render={(props) => <EditShipment {...props} />} />
                <Route path="/shipments" render={(props) => <ShipmentBatches {...props} />} />
                <Route path="/byrdStock" render={(props) => <ByrdStock {...props} />} />
                <Route path="/wepackStock" render={(props) => <WepackStock {...props} />} />
                <Route path="/" render={renderRootComponent} />
              </Switch>
            </Container>
          </Route>
        </Switch>
      </SearchContextWrapper>
    );
  }
}

const SearchContextWrapper = (props) => {
  const [activeFilters, dispatch] = useReducer(searchReducer, {
    searchQuery: "",
    product: "All",
    country: "All",
    source: "All",
    salesType: "All",
  });
  return (
    <SearchContext.Provider value={activeFilters}>
      <SearchDispatchContext.Provider value={dispatch}>{props.children}</SearchDispatchContext.Provider>
    </SearchContext.Provider>
  );
};

export default withRouter(App);
