import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Navbar, Nav } from "react-bootstrap";
import Auth from "./common/Auth";
import auth from "../services/authService";
import styled from "styled-components";
import Button from "@mui/material/Button";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import LightModeIcon from "@mui/icons-material/LightMode";

const ThemeToggleButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  border-radius: 50%;
  transition: background-color 0.3s;

  &:hover {
    background-color: #e0e0e0;
  }
`;
const UserContant = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 1rem;
  margin-right: 1rem;

  @media (max-width: 991px) {
    flex-direction: column;
    gap: 0.5rem;
    align-items: flex-start;
  }
`;
const LogoutBtn = styled(Button)`
  margin-left: 1rem;
  margin-right: 1rem;
  padding: 5px;

  a {
    padding: 0px;
  }
`;

class Menu extends Component {
  render() {
    const user = auth.getCurrentUser();
    const { toggleTheme, pathname, currentTheme } = this.props;

    return (
      <Navbar expand="lg">
        <Navbar.Brand href="/">
          <strong>Norwegian Lab</strong> - Admin panel
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            <li className={pathname === "/" ? "nav-item active" : "nav-item"}>
              <Link className="nav-link" to="/">
                Home
              </Link>
            </li>

            <Auth roles={["support", "admin"]}>
              <li className={pathname === "/customers" ? "nav-item active" : "nav-item"}>
                <Link className="nav-link" to="/customers">
                  Customers
                </Link>
              </li>
            </Auth>
            <Auth roles={["admin"]}>
              <li className={pathname === "/phones" ? "nav-item active" : "nav-item"}>
                <Link className="nav-link" to="/phones">
                  Phones
                </Link>
              </li>
            </Auth>
            <Auth roles={["admin"]}>
              <li className={pathname === "/shipments" ? "nav-item active" : "nav-item"}>
                <Link className="nav-link" to="/shipments">
                  Shipments
                </Link>
              </li>
            </Auth>
            {/* <Auth roles={["admin"]}>
              <li className={pathname === "/logs" ? "nav-item active" : "nav-item"}>
                <Link className="nav-link" to="/logs">
                  Logs
                </Link>
              </li>
            </Auth> */}
            <Auth roles={["admin"]}>
              <li className={pathname === "/blacklist" ? "nav-item active" : "nav-item"}>
                <Link className="nav-link" to="/blacklist">
                  Blacklist
                </Link>
              </li>
            </Auth>
            <Auth roles={["admin", "author"]}>
              <li className={pathname === "/articles/articleList" ? "nav-item active" : "nav-item"}>
                <Link className="nav-link" to="/articles/articleList">
                  Articles
                </Link>
              </li>
            </Auth>
          </Nav>
          {!user && (
            <span className={pathname === "/login" ? "nav-item active" : "nav-item"}>
              <Link className="nav-link" to="/login">
                Login
              </Link>
            </span>
          )}
          {user && (
            <UserContant>
              <span>
                Logged in: <strong>{user.name}</strong>
              </span>
              <LogoutBtn size="small" variant="outlined" className={pathname === "/logout" ? "nav-item active" : "nav-item"}>
                <Link className="nav-link" to="/logout">
                  Logout
                </Link>
              </LogoutBtn>
            </UserContant>
          )}
          <ThemeToggleButton className="theme-color" onClick={toggleTheme}>
            {currentTheme === "dark" ? <LightModeIcon /> : <DarkModeIcon />}
          </ThemeToggleButton>{" "}
        </Navbar.Collapse>
      </Navbar>
    );
  }
}

export default Menu;
