import React from "react";
import Form from "./common/Form";
import Dialog from "./common/Dialog";
import Subs from "./Subs";
import { Container, Row, Col } from "react-bootstrap";
import Joi from "joi-browser";
import { getCustomerById, updateCustomer, createCustomer, cleanupCustomer, postComment, changeFlag, getSmslog } from "../services/customerService";
import { getProducts } from "../services/productService";
import auth from "../services/authService";
import { toast } from "react-toastify";
import { getFlag } from "../utils/countries";
import SmsBtn from "../components/common/SmsBtn";
import Smslogs from "../components/common/Smslogs";
import date from "date-and-time";
import { sendInvitation } from "../services/trustpilotService";
import Orders from "./Orders";

class EditCustomer extends Form {
  state = {
    dialog: {},
    data: {
      name: "",
      street: "",
      postcode: "",
      postarea: "",
      email: "",
      phone: "",
      product: "",
      comment: "",
      events: [],
    },
    errors: {},
    customer: {},
    products: [],
    newcomment: "",
    showDelete: false,
    beenFlagged: false,
    smslogs: [],
    trustpilot: null,
    trustpilotDialog: {},
    showAllEvents: false,
  };

  schema = {
    phone: Joi.string().required().label("Phone"),
    name: Joi.string().required().label("Name"),
    street: Joi.string().required().label("Street"),
    postcode: Joi.string().required().label("PostCode"),
    postarea: Joi.string().required().label("PostArea"),
    email: Joi.string().allow("").email().label("Email"),
    product: Joi.string().required().label("Subscription"),
    comment: Joi.string().allow("").label("Comment"),
    comments: Joi.array().allow([]).label("Comments"),
    events: Joi.array().allow([]).label("Events"),
    flags: Joi.array().allow([]).label("Flags"),
    personId: Joi.string().allow("").label("Person ID"),
  };

  async componentDidMount() {
    const user = auth.getCurrentUser();
    if (user && (user.role === "support" || user.role === "admin")) {
      let { data, customer, products, beenFlagged, smslogs } = { ...this.state };
      if (this.props.match.params.customerid !== "new") {
        this.schema.product = Joi.string().allow("").label("Subscription");
        customer = await getCustomerById(this.props.match.params.customerid);
        smslogs = await getSmslog(customer.phone, customer.country);

        data.name = customer.name;
        data.street = customer.street;
        data.postcode = customer.postcode;
        data.postarea = customer.postarea;
        data.email = customer.email;
        data.phone = customer.phone;
        data.comments = customer.comments;
        data.events = customer.events;
        data.flags = customer.flags;
        if (customer.arvato?.personid) {
          data.personId = customer.arvato.personid;
        }

        for (let i = 0; i < data.flags.length; i++) {
          const flag = data.flags[i];
          if (flag.flagged) {
            beenFlagged = true;
          }
        }
      } else {
        let result = await getProducts();
        products.push("");

        for (const key in result) {
          products.push(result[key].name);
        }
      }

      this.setState({ data, customer, products, beenFlagged, smslogs, trustpilot: customer.trustpilotSent });
    }
    if (user && user.role === "admin") {
      this.setState({ showDelete: true });
    }
  }

  doSubmit = async () => {
    let { data, errors } = this.state;
    const customerId = this.props.match.params.customerid;
    if (customerId === "new") {
      try {
        await createCustomer(data);
        window.location = "/customers";
      } catch (ex) {
        if (ex.response && ex.response.status === 400) {
          errors.name = ex.response.data;
          this.setState({ errors });
        }
      }
    } else {
      try {
        delete data.product;
        data._id = customerId;
        await updateCustomer(data);
        delete data._id;
        toast.success("Customer saved.");
      } catch (ex) {
        if (ex.response && ex.response.status === 400) {
          errors.name = ex.response.data;
          this.setState({ errors });
        }
      }
    }
  };

  confirmDelete = (e) => {
    e.preventDefault();
    let { dialog } = this.state;
    dialog.open = true;
    dialog.id = this.props.match.params.customerid;
    this.setState({ dialog });
  };

  closeModal = () => {
    this.setState({ dialog: { open: false } });
  };

  delete = async (e) => {
    e.stopPropagation();
    let { dialog } = this.state;
    dialog.open = false;
    await cleanupCustomer(dialog.id);
    this.setState({ dialog });
    this.props.history.push("/customers");
  };

  handleCommentChange = (e) => {
    let newcomment = this.state.newcomment;
    newcomment = e.target.value;
    this.setState({ newcomment });
  };

  handleEnterPress = (e) => {
    if (e.keyCode === 13 && e.shiftKey === false) {
      e.preventDefault();
      this.addComment(e);
    }
  };

  addComment = async (e) => {
    e.preventDefault();
    if (this.state.newcomment) {
      const user = auth.getCurrentUser();
      const comments = await postComment(this.props.match.params.customerid, {
        comment: this.state.newcomment,
        user: user.name,
      });
      let { data, customer } = this.state;
      customer.comments = comments;
      data.comments = comments;
      this.setState({ data, customer, newcomment: "" });
    }
  };

  handleBack = (e) => {
    e.preventDefault();
    this.props.history.push("/customers");
  };

  handleFlag = async (e, toFlag) => {
    e.preventDefault();
    await changeFlag({ custno: this.state.customer.id, toFlag })
      .then((customer) => {
        const data = { ...this.state.data };
        data.flags = customer.flags;
        this.setState({ data });
        toast.success("Flag updated.");
      })
      .catch((err) => {
        toast.error("Something failed.");
      });
  };

  copyToClipboard = () => {
    const { phone, name, street, postcode, postarea, email } = this.state.data;
    const personId = this.state.customer.arvato?.personid;
    const text = [phone, name, street, `${postcode} ${postarea}`, email, personId ? `${personId}` : ""].filter(Boolean).join("\n");

    navigator.clipboard.writeText(text);
    toast.success("Customer data copied to clipboard.");
  };

  copyPersonId = () => {
    const personId = this.state.customer.arvato?.personid;
    if (personId) {
      navigator.clipboard.writeText(personId);
      toast.success("Person ID copied to clipboard!");
    }
  };

  confirmTrustpilot = async (e) => {
    e.stopPropagation();

    let { trustpilotDialog } = this.state;
    trustpilotDialog.open = true;
    trustpilotDialog.title = "Send Trustpilot invitation?";
    trustpilotDialog.text = "Sure you want to send a trustpilot invitation to this customer?";
    trustpilotDialog.doYes = this.sendTrustpilot;

    this.setState({ trustpilotDialog });
  };

  sendTrustpilot = async () => {
    try {
      const user = auth.getCurrentUser();
      await sendInvitation({ custId: this.props.match.params.customerid, subId: null, user: user.name });
      let trustpilot = null;
      const customer = this.state.customer;
      if (customer.trustpilotSent) trustpilot = customer.trustpilotSent;
      this.setState({ trustpilotDialog: { open: false }, trustpilot });
      toast.success("Trustpilot mail sent to queue.");
    } catch (err) {
      this.setState({ trustpilotDialog: { open: false } });
      toast.error("Something failed when trying to send invite.");
    }
  };

  closeTrustpilotModal = () => {
    this.setState({ trustpilotDialog: { open: false } });
  };

  toggleShowAllEvents = () => {
    this.setState((prevState) => ({
      showAllEvents: !prevState.showAllEvents,
    }));
  };

  render() {
    const { data, showAllEvents } = this.state;
    const events = data.events || [];
    const eventsToDisplay = showAllEvents ? events : events.slice(0, 5);

    return (
      <Container>
        <Dialog
          open={this.state.dialog.open}
          doNo={this.closeModal}
          doYes={this.delete}
          title="WARNING!!!"
          text="Are you 100% sure you want to delete this customer? (this action cannot be
            reverted and also will delete all subs and orders for this customer)"
          yes="Yes"
          no="No"
        />
        <Dialog
          open={this.state.trustpilotDialog.open}
          doNo={this.closeTrustpilotModal}
          doYes={this.state.trustpilotDialog.doYes}
          title={this.state.trustpilotDialog.title}
          text={this.state.trustpilotDialog.text}
          loading={this.state.trustpilotDialog.loading}
          yes="Yes"
          no="No"
        />
        <Row>
          <Col className="col-12 col-lg-6">
            <Row>
              <Col className="mt-3">
                {this.props.match.params.customerid !== "new" ? (
                  <h1>
                    Editing customer {this.state.customer.id} ({getFlag(this.state.customer.country)})
                  </h1>
                ) : (
                  <h1>Adding new customer</h1>
                )}
              </Col>
            </Row>
            {this.state.customer.arvato?.customerNo && (
              <Row>
                <Col>
                  <h3>Riverty custno: {this.state.customer.arvato.customerNo}</h3>
                </Col>
              </Row>
            )}
            <Row>
              <Col>
                <h3>
                  Created:{" "}
                  {this.state.customer.created &&
                    Intl.DateTimeFormat("no-NO", {
                      year: "numeric",
                      month: "numeric",
                      day: "numeric",
                      hour: "numeric",
                      minute: "numeric",
                      second: "numeric",
                    }).format(new Date(this.state.customer.created))}
                </h3>
              </Col>
            </Row>
            <Row>
              <Col>
                <h3>
                  Updated:{" "}
                  {this.state.customer.updated &&
                    Intl.DateTimeFormat("no-NO", {
                      year: "numeric",
                      month: "numeric",
                      day: "numeric",
                      hour: "numeric",
                      minute: "numeric",
                      second: "numeric",
                    }).format(new Date(this.state.customer.updated))}
                </h3>
              </Col>
            </Row>
            {this.state.customer?.birthDate && (
              <Row>
                <Col>
                  <h3>Date of birth: {date.format(new Date(this.state.customer.birthDate), "DD.MM.YYYY")}</h3>
                </Col>
              </Row>
            )}
            {events && events.length > 0 && <h3>Events:</h3>}
            {eventsToDisplay.map((event, index) => (
              <Row key={index}>
                <Col>{event.event}</Col>
                <Col>
                  {Intl.DateTimeFormat("no-NO", {
                    year: "numeric",
                    month: "numeric",
                    day: "numeric",
                    hour: "numeric",
                    minute: "numeric",
                    second: "numeric",
                  }).format(new Date(event.date))}
                </Col>
                <Col>{event.channel}</Col>
              </Row>
            ))}
            {events.length > 5 && (
              <Row className="mt-2">
                <Col>
                  <button className="btn btn-primary events-expand bg-blue" onClick={this.toggleShowAllEvents}>
                    {showAllEvents ? "Show Less" : "Show more events"}
                  </button>
                </Col>
              </Row>
            )}

            {this.state.data.flags && this.state.data.flags.length > 0 ? (
              <h3 className={this.state.data.flags[this.state.data.flags.length - 1].flagged ? "red" : ""}>
                Flagged:{" "}
                {this.state.data.flags[this.state.data.flags.length - 1].flagged ? (
                  <span>
                    Yes
                    <button className="btn btn-secondary ml-3 bg-blue" onClick={(e) => this.handleFlag(e, false)}>
                      Unflag
                    </button>
                  </span>
                ) : (
                  <span>
                    No
                    <button className="btn btn-danger ml-3 bg-red" onClick={(e) => this.handleFlag(e, true)}>
                      Flag
                    </button>
                  </span>
                )}
              </h3>
            ) : (
              <button className="btn btn-danger  bg-red" onClick={(e) => this.handleFlag(e, true)}>
                Flag
              </button>
            )}
            {this.state.beenFlagged && <h3>Been flagged: Yes</h3>}
            <Row>
              <Col className="mt-3">
                <form onSubmit={this.handleSubmit}>
                  <div className="customer-btn-row">
                    {this.renderButton("Save")}
                    {this.state.showDelete && (
                      <button className="btn btn-danger  bg-red" onClick={this.confirmDelete}>
                        Delete
                      </button>
                    )}
                    <button type="button" className="btn btn-secondary  bg-gray" onClick={this.copyToClipboard}>
                      Copy to clipboard
                    </button>
                    <button className="btn btn-secondary  bg-yellow" onClick={this.handleBack}>
                      Back
                    </button>
                  </div>
                  <SmsBtn customerId={this.state.customer._id} />
                  <Row>
                    <Col>{this.state.trustpilot && <h3>Trustpilot sent: {this.state.trustpilot && date.format(new Date(this.state.trustpilot), "DD.MM.YYYY HH:mm:ss")}</h3>}</Col>
                  </Row>
                  <Row>
                    <Col>
                      <input type="button" className="btn btn-primary bg-blue" value="Send Trustpilot Customer Support" onClick={(e) => this.confirmTrustpilot(e)} />
                    </Col>
                  </Row>
                  <div className="top-form-row"></div>
                  {this.state.customer.arvato?.personid && (
                    <div className="form-group">
                      {this.renderInput("personId", "Person ID", "text", true)}
                      <button type="button" className="btn btn-secondary mt-1 bg-gray" onClick={this.copyPersonId}>
                        Copy Person ID
                      </button>
                    </div>
                  )}
                  {this.renderInput("phone", "Phone")}
                  {this.renderInput("name", "Name")}
                  {this.renderInput("street", "Street")}
                  {this.renderInput("postcode", "PostCode")}
                  {this.renderInput("postarea", "PostArea")}
                  {this.renderInput("email", "Email")}
                  {this.props.match.params.customerid === "new" && this.renderDropdown("product", "Subscription", this.state.products)}
                </form>
              </Col>
            </Row>
          </Col>
          <Col className="col-12 col-lg-6 mt-3">
            <h1>Comments</h1>
            <div className="comments-wrapper">
              {this.state.customer.comments &&
                this.state.customer.comments.map((value, index) => {
                  return (
                    <div className="comment" key={index}>
                      <div className="comment-date">
                        Posted:{" "}
                        {Intl.DateTimeFormat("no-NO", {
                          year: "numeric",
                          month: "numeric",
                          day: "numeric",
                          hour: "numeric",
                          minute: "numeric",
                          second: "numeric",
                        }).format(new Date(value.created))}
                      </div>
                      <div className="comment-text">{value.comment}</div>
                      <div className="comment-user">By: {value.user}</div>
                    </div>
                  );
                })}
              <div className="comment-new">
                <strong>ADD COMMENT</strong>
                <textarea name="newcomment" onChange={this.handleCommentChange} onKeyDown={this.handleEnterPress} value={this.state.newcomment} rows="5"></textarea>
              </div>
              <div className="comment-button">
                <button className="btn btn-success" onClick={this.addComment}>
                  Post
                </button>
              </div>
            </div>
          </Col>
        </Row>
        {this.props.match.params.customerid !== "new" && this.state.customer.orders !== undefined && this.state.customer.orders.length > 0 && (
          <Row>
            <Col>
              <Orders orders={this.state.customer.orders} {...this.props} pageName="Single Order" singleOrders />
            </Col>
          </Row>
        )}
        {this.state.customer.subs && this.state.customer.subs.length > 0 && (
          <Row>
            <Col>{this.props.match.params.customerid !== "new" && <Subs customer={this.state.customer} {...this.props} />}</Col>
          </Row>
        )}
        <Row>
          <Col>{this.props.match.params.customerid !== "new" && <Smslogs smslogs={this.state.smslogs} />}</Col>
        </Row>
      </Container>
    );
  }
}

export default EditCustomer;
