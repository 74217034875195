import React from "react";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, AreaChart, Area } from "recharts";
import { Typography, Box } from "@mui/material";

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    const dateFormat = new Intl.DateTimeFormat("no-NO", {
      month: "short",
      day: "numeric",
    });

    return (
      <Box
        sx={{
          bgcolor: "background.paper",
          p: 1,
          border: 1,
          borderColor: "grey.300",
          borderRadius: 1,
        }}
      >
        <Typography variant="body2">{dateFormat.format(typeof label === "number" ? new Date(label) : label)}</Typography>
        {payload.map((entry, index) => (
          <Typography key={index} variant="body2" sx={{ color: entry.color }}>
            {entry.name}: {entry.value}
          </Typography>
        ))}
      </Box>
    );
  }
  return null;
};

const formatChartData = (data) => {
  if (!data) return [];
  return data.map((point) => ({
    ...point,
    x: new Date(point.x).getTime(), // Ensure x is a timestamp
  }));
};

export const TrailingChart = ({ data, avg, avgLabel, width, height }) => {
  const formattedData = formatChartData(data);
  const formattedAvg = formatChartData(avg);

  return (
    <ResponsiveContainer width="100%" height={height}>
      <LineChart margin={{ top: 30, right: 30, left: 20, bottom: 5 }}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="x"
          type="number"
          domain={["auto", "auto"]}
          scale="time"
          tickFormatter={(timestamp) => {
            return new Intl.DateTimeFormat("no-NO", {
              month: "short",
              day: "numeric",
            }).format(new Date(timestamp));
          }}
        />
        <YAxis />
        <Tooltip content={<CustomTooltip />} />
        <Legend />
        <Line
          data={formattedData}
          type="monotone"
          dataKey="y"
          stroke="#8884d8"
          name="Orders"
          dot={true}
          label={(props) => {
            const { x, y, value } = props;
            return (
              <text
                x={x}
                y={y - 10}
                fill="#8884d8"
                textAnchor="middle"
                fontSize="12"
                fontWeight="bold"
                style={{
                  filter: "drop-shadow(0px 0px 2px white)",
                }}
              >
                {value}
              </text>
            );
          }}
          strokeWidth={2}
          isAnimationActive={false}
        />
        {avg && (
          <Line
            data={formattedAvg}
            type="monotone"
            dataKey="y"
            stroke="#82ca9d"
            name="Average"
            dot={(props) => {
              // Only show dot for the last point
              const { index, payload } = props;
              return index === formattedAvg.length - 1;
            }}
            label={(props) => {
              const { x, y, value, index } = props;
              // Only show label for the last point
              if (index === formattedAvg.length - 1) {
                return (
                  <text
                    x={x}
                    y={y - 10}
                    fill="#82ca9d"
                    textAnchor="middle"
                    fontSize="12"
                    fontWeight="bold"
                    style={{
                      filter: "drop-shadow(0px 0px 2px white)",
                    }}
                  >
                    {Math.round(value)}
                  </text>
                );
              }
              return null;
            }}
            strokeWidth={2}
            isAnimationActive={false}
          />
        )}
      </LineChart>
    </ResponsiveContainer>
  );
};

export const LifetimeChart = ({ data, width, height }) => {
  const formattedData = formatChartData(data);

  return (
    <ResponsiveContainer width="100%" height={height}>
      <AreaChart data={formattedData} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
        <defs>
          <linearGradient id="colorOrders" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
            <stop offset="95%" stopColor="#8884d8" stopOpacity={0.1} />
          </linearGradient>
        </defs>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="x"
          type="number"
          scale="time"
          domain={["auto", "auto"]}
          tickFormatter={(timestamp) => {
            return new Intl.DateTimeFormat("no-NO", {
              month: "short",
              day: "numeric",
            }).format(new Date(timestamp));
          }}
        />
        <YAxis />
        <Tooltip content={<CustomTooltip />} />
        <Area type="monotone" dataKey="y" stroke="#8884d8" fillOpacity={1} fill="url(#colorOrders)" name="Orders" isAnimationActive={false} />
      </AreaChart>
    </ResponsiveContainer>
  );
};

export const SubscriberTrendChart = ({ data, width, height }) => {
  const formattedData = formatChartData(data);

  return (
    <ResponsiveContainer width="100%" height={height}>
      <LineChart data={formattedData} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="x"
          type="number"
          scale="time"
          domain={["auto", "auto"]}
          tickFormatter={(timestamp) => {
            return new Intl.DateTimeFormat("no-NO", {
              month: "short",
              day: "numeric",
            }).format(new Date(timestamp));
          }}
        />
        <YAxis />
        <Tooltip content={<CustomTooltip />} />
        <Line type="monotone" dataKey="y" stroke="#82ca9d" name="Subscribers" dot={true} strokeWidth={2} isAnimationActive={false} />
      </LineChart>
    </ResponsiveContainer>
  );
};
