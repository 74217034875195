// theme.js
import { createTheme } from "@mui/material/styles";

const lightTheme = createTheme({
  palette: {
    mode: "light",
    background: {
      default: "#ffffff",
      paper: "#f8f9fa",
    },
    text: {
      primary: "#000000",
    },
    primary: {
      main: "#1976d2",
    },
    // Add other custom colors or overrides as needed
  },
});

const darkTheme = createTheme({
  palette: {
    mode: "dark",
    background: {
      default: "#343a40",
      paper: "#212529",
    },
    text: {
      primary: "#ffffff",
    },
    primary: {
      main: "#90caf9",
    },
    // Add other custom colors or overrides as needed
  },
});

export { lightTheme, darkTheme };
