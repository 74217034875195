import React from "react";

export const getFlag = (countryCode) => {
  const code = countryCode.toUpperCase();
  return (
    <img
      src={`https://flagcdn.com/24x18/${countryCode}.png`}
      srcSet={`https://flagcdn.com/48x36/${countryCode}.png 2x`}
      width="20"
      height="15"
      alt={`${code} flag`}
      style={{ verticalAlign: "middle" }}
    />
  );
};
